
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
  },
  setup() {
    return {
    }
  },
})
